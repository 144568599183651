
import './navbar.css'
import  logo from '../asset/Sohei.png'
import { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";



const NavMenu = () => {
  

  return (
    <div className="nav__menu-container ">
      <div className="nav__menu-container-link scale-up-ver-top">
        <ul className="nav__menu-link">
          <li><a href="https://docs.sohei.io/">Documentation</a></li>
          <li><a href="https://t.me/Sohei_io">Community</a></li>
        </ul>
          <button className="nav__menu-btnn" onClick={() => window.open('"https://app.camelot.exchange/?token2=0x7e70e4efbbcc72f21979eb029efa38ecb40238c6"', '_blank')}>
            <a href="#" >BUY HEI</a>
          </button>
      </div>
      <div className="nav__menu-container-link-sign" />
    </div>
  );
};



const Navbar = () => {

  const[toggle, setToggle]= useState(false)


 

 

  return (
    <nav>
        <div className='container nav__container'>
            <a href="index.html" className='nav__logo'>
                <img src={logo} alt="logo"/>
            </a>
            <ul className = "nav__menu" data-aos='zoom-in' >
                <li><a href="https://docs.sohei.io/"  target="_blank" rel="noopener noreferrer">Docs</a></li>
                <li><a href="https://docs.sohei.io/ecosystem/hei-token/what-is-hei"  target="_blank" rel="noopener noreferrer"> About Hei</a></li>
                <li><a href="https://t.me/Sohei_io"  target="_blank" rel="noopener noreferrer">Community</a></li>            
            </ul>

            <button className='btnn' onClick={() => window.open("https://app.camelot.exchange/?token2=0x7e70e4efbbcc72f21979eb029efa38ecb40238c6", '_blank')}> <a href="#">BUY HEI</a> </button>

          
                
               
            <div className='navbar-menu  ' >
              <div className="hamburgar" data-aos=' zoom-out'>
                    {toggle
                  ?
                    <RiCloseLine color="#fff" size={35} onClick={()=>setToggle(false)}  data-aos="flip-left"/>
                  :<RiMenu3Line color="#fff" size={30} onClick={()=>setToggle(true)}  data-aos="flip-right"/>
                
                  }
              </div>

              {toggle&& <NavMenu />} 
            </div> 
          
       

             
            
        </div>

    </nav>
  )
}

export default Navbar
