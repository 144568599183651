
import "./header.css"


const Header = () => {


  return (
    <header id='#home' >
      <div className='bg-video'  data-aos="zoom-in" data-aos-duration="3000">
      
       
        <div className='header__title'>
            <h1>Revolutionizing<br/>Liquidity Markets</h1>
             <p>Unlock the power of your assets. Borrow and lend with ease.</p>
              <div className='button__div'>
              <button className='header__btnOne' onClick={() => window.open('https://app.sohei.io', '_blank')}>
                <a href="#">BORROW</a>
              </button>
              <button className='header__btnTwo' onClick={() => window.open('https://app.sohei.io', '_blank')}>
                <a href="#">LEND</a>
              </button>

             </div> 
        </div>
      </div>


    </header>
  )
}

export default Header
